<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-card title="نظرات">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
      size="sm"><i class="fa fa-bars"></i></b-button>
    <b-collapse id="filterBox" class="my-2">
      <b-card>
        <form @submit.prevent="searchItems()" id="search-item">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="title">نظر</label>
                <b-form-input id="title" v-model="searchParams.comment"></b-form-input>
              </div>
            </div>
            <div class="col-md-4">
              <b-form-group label="محصول" label-for="product">
                <!-- <input type="hidden" name="product_id" :value="product.id"> -->
                <multiselect open-direction="bottom" v-model="product" :options="products" label="title" track-by="id"
                  :searchable="true" :close-on-select="true" :show-labels="false"></multiselect>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="وضعیت" label-for="status">
                <b-form-select id="status" name="status" v-model="searchParams.status">
                  <b-form-select-option value="1">فعال</b-form-select-option>
                  <b-form-select-option value="0">غیرفعال</b-form-select-option>
                  <b-form-select-option value="">همه</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
          </div>
          <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
            جو</button>
        </form>
      </b-card>
    </b-collapse>
      <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">

        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | persianDate }}
        </template>

        <template v-slot:cell(status)="data">
          <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
          <span class="badge badge-light" v-else>غیرفعال</span>
        </template>
        <template v-slot:cell(product_id)="data">
          {{ data.item.product.title }}
        </template>
        <template v-slot:cell(customer_id)="data">
          {{ data.item.customer.first_name }} {{ data.item.customer.last_name }}
        </template>
        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
    </pagination>
    <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
    </pagination>

    <b-modal id="edit-modal" title="ویرایش نظر" hide-footer size="xl">
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <div class="row">
            <div class="col-md-6">
              <b-form-group label="نظر" label-for="comment">
                <b-form-textarea rows="6" id="comment" name="comment" v-model="edit.comment" data-required
                  :disabled="disabled"></b-form-textarea>
              </b-form-group>
              <div class="form-group">
                <label class="custom-switch">
                  <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status"
                    :disabled="disabled">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">وضعیت</span>
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <b-form-group label="پاسخ" label-for="response">
                <b-form-textarea rows="6" id="response" name="response" v-model="edit.response" :disabled="disabled">
                </b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <div class="clearfix">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="put" name="_method">

        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import mixins from './mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/comments',
        title: 'نظرات',
        comments: [],
        products: [],
        product: [],
        selected_filter_status: '',
        table_fields: [{
            key: 'id',
            label: 'شناسه '
          },
          {
            key: 'comment',
            label: 'نظر'
          },
          {
            key: 'product_id',
            label: 'محصول'
          },
          {
            key: 'customer_id',
            label: 'مشتری'
          },
          {
            key: 'status',
            label: 'وضعیت'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          {
            key: 'edit',
            label: 'ویرایش '
          },
          {
            key: 'delete',
            label: 'حذف'
          }
        ]
      }
    },
    methods: {
      loadComments() {
        this.$axios.get(this.$root.baseUrl + '/api/admin/comments')
          .then(response => {
            this.comments = response.data.data.data
          })
      },
      loadProducts() {
        this.$axios.get(this.$root.baseUrl + '/api/admin/list/products')
          .then(response => {
            this.products = response.data.data
          })

      },
      moment(...args) {
        return window.moment(...args)
      },
      searchItems(page = 1) {
            this.disabled = true
            this.search = true
            this.product != null ? this.searchParams.product_id = this.product.id : ''
            this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page , {params: this.searchParams})
              .then(response => {
                this.search = true
                this.items = response.data
              })
              .catch(error => {
                this.$root.error_notification(error)
              })
              .finally(() => {
                this.disabled = false
              })
          },
    },
    created() {
      this.loadProducts()
      // this.loadComments()
      this.loadItems()
      this.searchParams = {
        comment: '',
        product_id: '',
        status: '',
      }
    }
  }
</script>